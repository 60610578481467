<style scoped>
	.template {height: 100%;width: 100%;overflow: hidden;position: relative;}
	.page_box {height: 100%;width: 100%;overflow: hidden;display: flex;flex-direction: column;}
	.header_box{padding:10px 5px 5px 10px;}
	.main_box {flex: 1;overflow: hidden;}
	.main_box_content{height: 100%;overflow-y: auto;padding: 0 10px 0 10px;}
	.footer_box{text-align: right;padding: 10px 30px 10px 10px;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
	
	.demo-table-expand {font-size: 0;}
	.demo-table-expand label {width: 90px;color: #99a9bf;}
	.demo-table-expand .el-form-item {margin-right: 0;margin-bottom: 0;width: 50%;}
</style> 
<template>
	<div class="template">
		<div class="page_box">
			<div class="header_box">
				<el-button type="warning" size="small" @click="addCommunity">添加角色<i class="el-icon-circle-plus-outline"></i></el-button>
				<el-button type="danger" size="small" @click="api_getCommunityList">刷新<i class="el-icon-refresh-right"></i></el-button>
			</div>
			<div class="main_box" id="main_box">
				<div class="main_box_content" v-loading="tableLoading">
					<el-table :data="communityList" style="width: 100%" :height="tableHeight" border>
						
						<el-table-column prop="name" label="角色名" width=""></el-table-column>
						<el-table-column prop="update_time" label="更新日期" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column fixed="right" label="操作" width="160">
							<template slot-scope="scope">
								<el-row style="padding-right: 10px;">
									<el-button @click="editCommunity(scope.row)" type="text" size="mini">编辑</el-button>
									<el-button @click="deleteCommunity(scope.row)" type="danger" size="mini">删除</el-button>
								</el-row>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="footer_box">

			</div>
		</div>
		<!-- 添加角色弹窗 -->
		<el-dialog custom-class="my_dialog" width="60%" top="none" title="添加角色" :visible.sync="dialogAddCommunity"
		 destroy-on-close>
			<add_role v-on:childrenEvent="handlerChildrenEvent"></add_role>
		</el-dialog>

		<!-- 添加角色弹窗 -->
		<el-dialog custom-class="my_dialog" width="60%" top="none" title="编辑角色" :visible.sync="dialogEditCommunity"
		 destroy-on-close>
			<edit_role v-if="dialogEditCommunity" :id="selectCommunityId" v-on:childrenEvent="handlerChildrenEventByEditCommiunity"></edit_role>
		</el-dialog>
	</div>
</template>

<script>
	import add_role from './add_role.vue'
	import edit_role from './edit_role.vue'
	var _this;
	export default {
		components: {
			add_role,
			edit_role
		},
		data() {
			return {
				tableHeight: 0,
				tableLoading: true,
				communityList: [],
				dialogAddCommunity: false, //添加小区弹窗
				dialogEditCommunity: false, //编辑小区弹窗
				selectCommunityId: 0,//选取的角色id
			}
		},
		created: function() {
			_this = this;
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("main_box").offsetHeight;
				_this.api_getCommunityList();
			})
		},
		methods: {
			/* * 获取角色列表 */
			api_getCommunityList() {
				_this.tableLoading = true;
				_this._getApi('/wy/role/getList', {})
					.then((res) => {
						if (res.code == 1) {
							_this.communityList = res.data.list;
							_this.tableLoading = false;
						} else {

						}
					})
					.catch((err) => {})
			},

			// _ ** 表格占位
			formatterCellval(row, column, cellValue, index) {
				//console.log(Boolean(cellValue), cellValue);
				if (!cellValue) {
					return "--";
				} else {
					return cellValue;
				}
			},

			// _ ** 添加角色
			addCommunity() {
				_this.dialogAddCommunity = true;
			},

			// _ ** 编辑角色
			editCommunity(object) {
				_this.selectCommunityId = object.id;
				_this.dialogEditCommunity = true;
			},

			// _ ** 删除某个角色
			deleteCommunity(object) {
				var h = _this.$createElement;
				_this.$msgbox({
					title: '不可逆操作提示',
					message: h('p', null, [
						h('span', null, '确定要删除 '),
						h('i', {
							style: 'color: teal'
						}, object.name),
						h('span', null, ' 角色吗? ')
					]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							_this._postApi('/wy/role/delete', {
								id: object.id
							}).then((res) => {
								if (res.code == 1) {
									done();
									_this.api_getCommunityList();
									setTimeout(() => {
										instance.confirmButtonLoading = false;
									}, 300);
								} else {
									_this.$message.error(res.msg);
									done();
									instance.confirmButtonLoading = false;
								}
							}).catch((err) => {

							});
						} else {
							done();
						}
					}
				}).then(action => {
					// this.$message({
					// 	type: 'info',
					// 	message: 'action: ' + action
					// });
				});
			},

			// _ ** 处理添加角色子组件事件
			handlerChildrenEvent(e) {
				_this.api_getCommunityList();
				_this.dialogAddCommunity = false;
			},
			// _ ** 处理编辑小区子组件事件
			handlerChildrenEventByEditCommiunity(e) {
				_this.api_getCommunityList();
				_this.dialogEditCommunity = false;
			}
		}
	}
</script>
