<style scoped>
	.page_box_zu{height: 70vh;display: flex;flex-direction: column;}
	.page_box_zu_body{flex: 1;overflow-y: auto;}
	.page_box_zu_footer{text-align: right;}
	/* 表单 */
	
	.z_form_item_row{padding: 10px 0;}
	.z_form_item{flex: 1;display: flex;flex-direction: row;}
	.z_form_item.c{align-items:center ;}
	.z_form_item_label{width: 100px;text-align: right;padding-right: 10px;}
	.z_form_item_value{flex: 1;}
	.z_form input{height: 30px;width: 100%;}
	.z_form textarea{height: 80px;width: 100%;padding: 5px;}
	
	
</style>
<style>
	.z_form .el-form-item{margin-bottom: 0;}
	.z_form .el-form-item__content{margin-left: 0!important;}
	.z_form .el-tree-node__label{font-size: 1.1rem;}
	.z_form .el-tree-node__content{height: 2.5rem;}
	.z_form .el-checkbox__inner{width: 1.5rem;height: 1.5rem;}
	.z_form .el-checkbox__inner::after{content: ""; top: 0;bottom: 0;left: 0;right: 0;margin: auto;}
	.z_form .el-checkbox__input.is-indeterminate .el-checkbox__inner::before{content: ""; top: 0;bottom: 0;left: 0;right: 0;margin: auto;}
</style>
<template>
	<div class="page_box_zu">
		<div class="page_box_zu_header"></div>
		<div class="page_box_zu_body z_form">
			<el-form label-width="80px">
				<el-form-item prop="name">
					<div class="z_form_item c">
						<div class="z_form_item_label">角色名</div>
						<div class="z_form_item_value">
							<el-input placeholder="必填" v-model="name" type="text"></el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="name">
					<div class="z_form_item" style="padding-top: 20px;">
						<div class="z_form_item_label">权限设置</div>
						<div class="z_form_item_value">
							<el-tree ref="tree" :data="roleData" show-checkbox node-key="id" :props="defaultProps" default-expand-all
							 @check="getCheckedKeys" :check-strictly="checkStrictly" :default-checked-keys="hasRoleList">
							</el-tree>
						</div>
					</div>

				</el-form-item>
			</el-form>
		</div>
		<div class="page_box_zu_footer">
			<el-button>取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="api_save">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['id'],
		data() {
			return {
				btnSaveLoading: false,
				roleData: [],
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				name: '', //角色名字
				roleList: [], //选择的权限列表
				hasRoleList:[],//拥有的权限列表
				checkStrictly:true,//避免避免回旋时全选
			}
		},
		created: function() {
			_this = this;
			_this.roleList = _this.hasRoleList = [];
			_this.api_getTreeData();
		},
		mounted: function() {

		},
		methods: {
			// _ ** 获取tree数据
			api_getTreeData() {
				_this._getApi('/wy/account/getAuthListByAdminInfo', {}).then((res) => {
					if (res.code == 1) {
						_this.roleData = res.data;
						_this.api_getRoleInfo();
					} else {

					}
				}).catch((err) => {

				})
			},

			// _ ** 获取该角色的信息
			api_getRoleInfo() {
				var params = {};
				params.id = _this.id;
				_this._getApi('/wy/role/getInfo', params).then((res) => {
					if (res.code == 1) {
						var baseData =  res.data;
						_this.hasRoleList = baseData.rule_ids.split( ',' );
						_this.roleList = baseData.rule_ids.split( ',' );
						// _this.hasRoleList = _this.hasRoleList.map(Number);
						//this.$refs.tree.setCheckedKeys(_this.hasRoleList,true);
						_this.checkStrictly = false;//避免
						_this.name = baseData.name;
					} else {

					}
				}).catch((err) => {

				})
			},

			// _ ** checkbox选择事件
			getCheckedKeys(val) {
				var res = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
				_this.roleList = res;
				console.log(_this.roleList)
			},

			// _ ** 保存
			api_save() {
				var params = {};
				params.name = _this.name;
				params.rule_ids = _this.roleList;
				params.id = _this.id;
				_this._postApi('/wy/role/update', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent', '');
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			},
		}
	}
</script>
